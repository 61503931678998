import React from 'react';
import loadable from '@loadable/component';
import LoadingComponent from './OrganizationsPageLoading';

const OrganizationsPage = loadable(
  () => import(
    /* webpackChunkName: "organizations-page-chunk" */
    './OrganizationsPage'
  ),
  {
    fallback: (
      <LoadingComponent />
    ),
  }
);

export default OrganizationsPage;
