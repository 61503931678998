import React from 'react';
import loadable from '@loadable/component';
import LoadingComponent from './ReviewPageLoading';

const OrganizationPage = loadable(
  () => import(
    /* webpackChunkName: "review-page-chunk" */
    './ReviewPage'
  ),
  {
    fallback: (
      <LoadingComponent />
    ),
  }
);

export default OrganizationPage;