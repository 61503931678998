import React from 'react';
import loadable from '@loadable/component';
import ProjectLoading from '../ProjectLoading';

const ProjectCreatePage = loadable(
  () => import(
    /* webpackChunkName: "project-create-chunk" */
    './ProjectCreatePage'
  ),
  {
    fallback: (
      <ProjectLoading />
    ),
  }
);

export default ProjectCreatePage;
