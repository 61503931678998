import React from 'react';
import { css } from '@emotion/react';

const ProjectLoading = () => {
  const blockCss = css({
    height: '34px',
    background: '#dcdcdc',
    borderRadius: '3px',
    width: '50%',
  });
  const flexCss = css({
    display: 'flex',
    flexDirection: 'row',
  });
  const paddingCss = css({
    padding: '46px 24px 0 24px',
  });
  const blockFormCss = css([flexCss, paddingCss]);

  return (
    <div className="pageMainWrapper">
      <div className="pageMainWrapper__header">
        <div
          className="backgroundLoading"
          css={blockCss}
        />
      </div>
      <div className="pageMainWrapper__container-main">
        <div
          css={blockFormCss}
        >
          <div
            className="backgroundLoading"
            css={blockCss}
          />
          <div
            className="backgroundLoading --marginLeft-24"
            css={blockCss}
          />
          <div
            className="backgroundLoading --marginLeft-24"
            css={blockCss}
          />
        </div>
        <div
          css={blockFormCss}
        >
          <div
            className="backgroundLoading"
            css={blockCss}
          />
          <div
            className="backgroundLoading --marginLeft-24"
            css={blockCss}
          />
          <div
            className="backgroundLoading --marginLeft-24"
            css={blockCss}
          />
        </div>
        <div css={paddingCss}>
          <div
            className="backgroundLoading"
            css={blockCss}
          />
        </div>
        <div css={paddingCss}>
          <div
            className="backgroundLoading"
            css={blockCss}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectLoading;
