import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { bgLoadingCss } from '../../helpers/stylesHelper';

const LoadingRow = () => {
  const wrapCss = css({
    margin: '25px',
    minWidth: '350px',
  });
  const titleCss = css({
    height: '14px',
    borderRadius: '3px',
    width: '50%',
  });
  const titleBlockCss = css([
    titleCss,
    bgLoadingCss,
  ]);

  const descriptionCss = css({
    height: '34px',
    borderRadius: '3px',
    marginTop: '10px',
  });
  const descriptionBlockCss = css([
    descriptionCss,
    bgLoadingCss,
  ]);

  return (
    <div css={wrapCss}>
      <div css={titleBlockCss} className="backgroundLoading" />
      <div css={descriptionBlockCss} className="backgroundLoading" />
    </div>
  );
};

const OrganizationLoading = (props) => {
  const { isHideHeader = false } = props;
  const headerRowCss = css({
    height: '34px',
    borderRadius: '3px',
    width: '50%',
  });
  const blockHeaderCss = css([
    headerRowCss,
    bgLoadingCss,
  ]);

  const photoCss = css({
    height: '130px',
    minHeight: '130px',
    width: '130px',
    borderRadius: '50%',
  });
  const blockPhotoCss = css([
    photoCss,
    bgLoadingCss,
  ]);

  return (
    <div className="pageMainWrapper">
      {!isHideHeader && (
        <div className="pageMainWrapper__header">
          <div css={blockHeaderCss} className="backgroundLoading" />
        </div>
      )}
      <div className="pageMainWrapper__content">
        <div className="pageMainWrapper__left">
          <div className="form">
            {[...Array(5).keys()].map((index) => (
              <LoadingRow
                key={index}
              />
            ))}
          </div>
        </div>
        <div css={blockPhotoCss} className="backgroundLoading" />
      </div>
    </div>
  );
};
OrganizationLoading.propTypes = {
  isHideHeader: PropTypes.bool,
};

export default OrganizationLoading;
