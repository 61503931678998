import React from 'react';
import loadable from '@loadable/component';
import LoadingComponent from '../../components/LoadingComponent';

const ForgotPWDPage = loadable(
  () => import(
    /* webpackChunkName: "forgot-pwd-page-chunk" */
    './ForgotPWDPage'
  ),
  {
    fallback: (<LoadingComponent />),
  }
);

export default ForgotPWDPage;
