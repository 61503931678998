import React from 'react';
import loadable from '@loadable/component';
import PlanningPageLoading from './PlanningPageLoading';

const PlanningPage = loadable(
  () => import(
    /* webpackChunkName: "planning-page-chunk", webpackPrefetch: true */
    './PlanningPage'
  ),
  {
    fallback: (
      <PlanningPageLoading />
    ),
  }
);

export default PlanningPage;
