import React from 'react';
import loadable from '@loadable/component';
import LoadingComponent from '../../components/LoadingComponent';

const ConfirmationPage = loadable(
  () => import(
    /* webpackChunkName: "confirmation-page-chunk" */
    './ConfirmationPage'
  ),
  {
    fallback: (<LoadingComponent />),
  }
);

export default ConfirmationPage;