import React from 'react';
import { css } from '@emotion/react';
import cookieHelper from '../../utils/cookieHelper';

const OrganizationsPageLoading = () => {
  const titleCss = css({
    height: '34px',
    background: '#dcdcdc',
    borderRadius: '3px',
  });
  const btnDefCss = css({
    height: '30px',
    width: '75px',
    backgroundColor: '#fff',
    borderRadius: '3px',
    boxShadow: '0 1px 5px 0 rgb(0 0 0 / 20%)',
  });

  const btnSmall = css([btnDefCss, { height: '26px' }]);

  const renderHeader = () => (
    <div className="pageMainWrapper__header">
      <div className="pageMainWrapper__header-left --marginRight-25">
        <div
          className="backgroundLoading"
          css={titleCss}
        />
      </div>
      <div className="blank" />
    </div>
  );

  const labelCss = css({
    height: '16px',
    width: '70%',
    backgroundColor: '#9a9a9a',
    borderRadius: '3px',
  });
  const inputCss = css({
    height: '28px',
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #dcdcdc',
    borderRadius: '3px',
    marginTop: '8px',
    marginBottom: '20px',
  });

  const renderSelector = () => (
    <div>
      <div className="backgroundLoading" css={labelCss} />
      <div css={inputCss} />
    </div>
  );

  const wrapFieldCss = css({
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
  });
  const boxCss = css({
    height: '22px',
    width: '22px',
    backgroundColor: '#eaeaea',
    borderRadius: '3px',
  });

  const renderCheckbox = (index = '') => (
    <div key={index} css={wrapFieldCss}>
      <div css={boxCss} />
      <div className="--marginLeft-10" css={labelCss} />
    </div>
  );

  const renderCheckboxBlock = () => (
    <div className="--marginTop-20">
      <div className="backgroundLoading" css={labelCss} />
      {renderCheckbox()}
    </div>
  );

  const renderCheckList = () => (
    <div className="--marginTop-20">
      <div className="backgroundLoading" css={labelCss} />
      {[...Array(7).keys()].map((index) => renderCheckbox(index))}
    </div>
  );

  const renderFilterBar = () => (
    <div
      className="filterBar"
      css={{
        width: cookieHelper.get('filterBarWidth') ? +cookieHelper.get('filterBarWidth') : 'auto',
      }}
    >
      <div className="filterBar__wrap">
        <div className="filterBar__actions">
          <div css={btnSmall} />
        </div>
        <div className="filterBar__container">
          <div className="filtersList --marginTop-10">
            {renderSelector()}
            {renderSelector()}
            {renderSelector()}

            {renderCheckboxBlock()}

            {renderCheckList()}
          </div>
        </div>
      </div>
    </div>
  );

  const tableHeader = css({
    display: 'flex',
    alignItems: 'center',
    background: '#fafafa',
    height: '40px'
  });
  const tableBody = css({
    height: '100%',
    minHeight: '1px',
    overflow: 'auto',
    flex: 1,
  });

  return (
    <div className="pageMainWrapper">
      {renderHeader()}
      <div className="pageMainWrapper__container">
        <div className="pageMainWrapper__container--collapse">
          {renderFilterBar()}
          <div className="pageMainWrapper__container-main">
            <div css={tableHeader} />
            <div css={tableBody} />
            <div className="pageMainWrapper__container--pagination" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationsPageLoading;
