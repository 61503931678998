import React from 'react';
import loadable from '@loadable/component';
import ProjectsLoading from './ProjectsLoading';

const ProjectsPage = loadable(
  () => import(
    /* webpackChunkName: "projects-chunk" */
    './ProjectsPage'
  ),
  {
    fallback: (
      <ProjectsLoading />
    ),
  }
);

export default ProjectsPage;
