import React from 'react';
import loadable from '@loadable/component';
import LoadingComponent from '../../components/LoadingComponent';

const LoginPage = loadable(
  () => import(
    /* webpackChunkName: "login-page-chunk" */
    './LoginPage'
  ),
  {
    fallback: (<LoadingComponent />),
  }
);

export default LoginPage;