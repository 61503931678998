import React from 'react';
import loadable from '@loadable/component';
import OrganizationLoading from './OrganizationLoading';

const OrganizationPage = loadable(
  () => import(
    /* webpackChunkName: "organization-page-chunk" */
    './OrganizationPage'
  ),
  {
    fallback: (<OrganizationLoading />),
  }
);

export default OrganizationPage;