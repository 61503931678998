import React from 'react';
import loadable from '@loadable/component';
import LoadingComponent from '../../components/LoadingComponent';

const ForgotWorkerPWDConfirmPage = loadable(
  () => import(
    /* webpackChunkName: "worker-pwd-confirm-page-chunk" */
    './ForgotWorkerPWDConfirmPage'
  ),
  {
    fallback: (<LoadingComponent />),
  }
);

export default ForgotWorkerPWDConfirmPage;
