import React from 'react';
import loadable from '@loadable/component';
import LoadingComponent from '../../components/LoadingComponent';

const ForgotPWDConfirmPage = loadable(
  () => import(
    /* webpackChunkName: "pwd-confirm-page-chunk" */
    './ForgotPWDConfirmPage'
  ),
  {
    fallback: (<LoadingComponent />),
  }
);

export default ForgotPWDConfirmPage;
