import React from 'react';
import loadable from '@loadable/component';
import config from '../../config';
import Preloader from '../../components/Preloader';

const BillingOverviewPage = loadable(
  () => import(/* webpackChunkName: "billing-page-chunk" */ './BillingOverviewPage'),
  {
    fallback: (
      <div className="pageMainWrapper">
        <Preloader isDefault={config.isLogisticForce} />
      </div>
    ),
  }
);

export default BillingOverviewPage;