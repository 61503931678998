import React from 'react';
import loadable from '@loadable/component';
import ProjectLoading from '../ProjectLoading';

const ProjectDetailPage = loadable(
  () => import(
    /* webpackChunkName: "project-detail-chunk" */
    './ProjectDetailPage'
  ),
  {
    fallback: (
      <ProjectLoading />
    ),
  }
);

export default ProjectDetailPage;
