import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from '@emotion/react';
import { Button, TextInput } from 'clevergig-lib';
import config from '../../config';
import globalConst from '../../constants';
import { getStringFromUrl } from '../../utils/utils';
import { getQueryStringParams } from '../../utils/serialize';
import { loginManagerRequest, loadAuth } from '../../redux/modules/auth';
import { pwManagerConfirmation } from '../../services/validates';
import { useTranslation } from '../../contexts/TranslationContext';
import Preloader from '../../components/Preloader/Preloader';
import LogoHeader from '../../components/Login/LogoHeader';

const mapStateToProps = () => ({});

const dispatchToProps = (dispatch) => ({
  loginManagerRequest: (params) => dispatch(loginManagerRequest(params)),
  loadAuth: () => dispatch(loadAuth()),
});

const ManagerInvitation = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { tr, currentLang } = useTranslation();

  const [params, setParams] = useState({
    password: '',
    confirmPsw: '',
  });
  const [errors, setErrors] = useState({
    password: '',
    confirmPsw: '',
    token: '',
  });
  const [loading, setLoading] = useState(false);

  /**
   * Set or clear error
   * @param {string} field value name
   * @param {string} msg Error message, if empty, clear error
   */
  const handleSetError = (field, msg = '') => setErrors((prevState) => ({
    ...prevState,
    [field]: msg,
  }));

  /**
   * Handle change state value
   * @param {object} event SyntheticEvent
   */
  const handleChangeValue = ({ target: { name, value } }) => {
    setParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    handleSetError(name);
  };

  /**
   * Handle change state value
   * @param {object} event SyntheticEvent
   */
  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  const handleSend = () => {
    const parsed = getQueryStringParams(location.search);
    const token = getStringFromUrl(parsed.invitation_token);

    const paramsObj = {};
    paramsObj.invitation_token = token;
    paramsObj.password = params.password;
    paramsObj.password_confirmation = params.confirmPsw;

    const errors = pwManagerConfirmation(paramsObj, tr);

    if (!isEmpty(errors)) {
      setErrors(errors);
      return;
    }

    setLoading(true);
    props.loginManagerRequest(paramsObj)
      .then(() => {
        props.loadAuth()
          .then(({ data }) => {
            const url = (data.attributes.manager_type === globalConst.EXTERNAL_TYPE)
              ? 'planning-shifts'
              : 'dashboard';
            navigate(`/${currentLang}/${url}`);
          });
      }).catch((err) => {
        setLoading(false);

        const isError = !isEmpty(err.errors) && err.errors[0] && err.errors[0].message;
        const errorMsg = isError || tr.messages.accessDenied;

        handleSetError('token', errorMsg);
      });
  };

  return (
    <div className="guestPage">
      <LogoHeader
        currentLang={currentLang}
      />
      <div className="guestPage__container">
        <div className="guestPage__block">
          <h1 className="guestPage__title">{tr.common.setPassword}</h1>
          <div className="guestPage__form">
            <TextInput
              id="password"
              type="password"
              name="password"
              label={`${tr.form.password} *`}
              wrapCssClass={{
                marginTop: '15px',
              }}
              inputCssClass={{
                height: '34px',
              }}
              value={params.password}
              placeholder={tr.form.password}
              error={errors.password}
              onChange={handleChangeValue}
            />
            <TextInput
              id="passwordConfirm"
              type="password"
              name="confirmPsw"
              label={`${tr.form.confirmPwd} *`}
              wrapCssClass={{
                marginTop: '15px',
              }}
              inputCssClass={{
                height: '34px',
              }}
              value={params.confirmPsw}
              placeholder={tr.form.confirmPwd}
              error={errors.confirmPsw}
              onChange={handleChangeValue}
              onKeyUp={handleKeyUp}
            />
            {errors.token && <span className="guestPage__error">{errors.token}</span>}
          </div>
          <div className="guestPage__action">
            <Button
              className="guestPage__action-btn"
              title={tr.common.setup}
              wrapCssClass={{
                minWidth: '208px',
                height: '32px',
                ...theme.buttonsPrimary,
              }}
              attr={{ 'data-qa': 'setup' }}
              onClick={handleSend}
            />
          </div>
        </div>
      </div>
      {loading && (<Preloader isDefault={config.isLogisticForce} />)}
    </div>
  );
};

ManagerInvitation.displayName = 'ManagerInvitation';
ManagerInvitation.propTypes = {
  loginManagerRequest: PropTypes.func.isRequired,
  loadAuth: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, dispatchToProps)(ManagerInvitation);
