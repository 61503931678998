import React from 'react';
import { css } from '@emotion/react';

const ProjectsTableLoading = () => {
  const blockCss = css({
    height: '30px',
    borderRadius: '3px',
  });

  return (
    <div className="pageMainWrapper__container">
      <div className="pageMainWrapper__container--table-footer">
        <table className="table">
          <thead className="table__header">
            <tr className="table__header__row">
              <th className="table__header__cell --fixTop" aria-label="empty1" />
            </tr>
          </thead>
          <tbody className="table__body">
            <tr className="table__body__row">
              <td className="table__body__cell">
                <div className="backgroundLoading" css={blockCss} />
              </td>
            </tr>
            <tr className="table__body__row">
              <td className="table__body__cell">
                <div className="backgroundLoading" css={blockCss} />
              </td>
            </tr>
            <tr className="table__body__row">
              <td className="table__body__cell">
                <div className="backgroundLoading" css={blockCss} />
              </td>
            </tr>
            <tr className="table__body__row">
              <td className="table__body__cell">
                <div className="backgroundLoading" css={blockCss} />
              </td>
            </tr>
            <tr className="table__body__row">
              <td className="table__body__cell">
                <div className="backgroundLoading" css={blockCss} />
              </td>
            </tr>
            <tr className="table__body__row">
              <td className="table__body__cell">
                <div className="backgroundLoading" css={blockCss} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="pageMainWrapper__container--pagination" />
    </div>
  );
};

export default ProjectsTableLoading;
